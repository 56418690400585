import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 6,
    title: "Happy with (Appi)us",
    desc:
        "Abbiamo le risposte ai tuoi problemi e ci piacerebbe risolverli con una buona dose di professionalità, e tanta felicità!",
    img: "/blog-image/happy.webp",
    page: "blog/happy_software_house",
    data: "10 Lug 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Trovare uno sviluppatore competente sta diventando troppo complesso?",
                },
                {
                    property: `og:title`,
                    content: articleData.title,
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    Trovare uno sviluppatore competente sta
                                    diventando troppo complesso? Hai difficoltà
                                    nella realizzazione di App per Android e
                                    iOS? Abbiamo le risposte ai tuoi problemi e
                                    ci piacerebbe risolverli con una buona dose
                                    di professionalità, e tanta felicità!
                                </p>
                                <p>
                                    Cosa c’entra la felicità con lo sviluppo di
                                    software? Molti potrebbero dire nulla, ma
                                    noi di Appius non siamo d’accordo.
                                    <br />
                                    Mentre la tecnologia continua a mutare ed
                                    evolversi, alcuni bisogni restano gli
                                    stessi. D’altronde, sono proprio i
                                    proprietari e gli ideatori di queste
                                    tecnologie ad essere influenzati in primis
                                    da bisogni di vario tipo. La felicità può
                                    essere sicuramente messa tra i principali.
                                    <br />
                                    Decidere a chi affidare la realizzazione di
                                    un software su misura per il proprio
                                    business è una scelta importante.
                                    Comprendiamo che stai mettendo parte della
                                    tua felicità - il risultato del tuo estro e
                                    del tuo ingegno - nelle mani di sviluppatori
                                    esperti con l’obiettivo di ottenere
                                    esattamente quello che avevi in mente.
                                </p>

                                <img
                                    src={
                                        "https://www.codemotion.com/magazine/wp-content/uploads/2020/05/developer-typing-on-keyboard-896x504.jpg"
                                    }
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Sviluppatore"
                                />
                                <br />

                                <p>
                                    Concretizzare un pensiero può risultare
                                    complesso e deludente se non sei affiancato
                                    da persone che condividono i tuoi stessi
                                    valori. In primis, quello di lavorare in
                                    pace e tranquillità a progetti a te cari e
                                    importanti per la crescita del tuo business.
                                    Siamo qui a posta! Accogliamo con entusiasmo
                                    la tua volontà di offrire un servizio
                                    migliore ai tuoi utenti. Ti aiuteremo a
                                    sviluppare un percorso di contatto più
                                    agevole e snello per i tuoi clienti e a dar
                                    vita alla tua idea imprenditoriale.
                                    Studieremo con te le soluzioni migliori per
                                    aiutarti a raggiungere i tuoi obiettivi.
                                </p>
                                <p>
                                    Ma perché vogliamo parlare di felicità?
                                    <br />
                                    Anche noi di Appius siamo esseri umani e,
                                    inevitabilmente, quando entriamo in contatto
                                    con i nostri clienti in automatico si
                                    innescano variabili e sensazioni interne. È
                                    indubbio che andremo a ricercare un feedback
                                    dalla persona che si rivolge a noi - quanto
                                    più questo sarà positivo, tanto più ci
                                    sentiremo realizzati, apprezzati ed
                                    appagati. È altrettanto intuibile che debba
                                    esserci un ritorno della medesima positività
                                    anche dall’altra parte. Ci piace essere
                                    bravi in quello che facciamo, ma per fare
                                    una considerazione oggettiva di questo
                                    abbiamo bisogno del confronto con l’altro.
                                    <br />
                                    Il lavoro ricopre una fetta fondamentale
                                    della nostra esistenza, come possiamo
                                    passare serenamente le giornate se quello
                                    che facciamo per così tanto tempo non ci
                                    regala soddisfazioni?
                                    <br />
                                    In Appius, quando ci presentiamo, lo
                                    facciamo sottolineando che siamo una happy
                                    Software House. Perché abbiamo a cuore i
                                    nostri collaboratori e i nostri clienti.{" "}
                                    <br />
                                    Quando sviluppiamo App e soluzioni Web
                                    innovative combiniamo professionalità,
                                    innovazione e felicità. È questo il nostro
                                    biglietto da visita. È questo il risultato
                                    che vogliamo ottenere: un cliente
                                    soddisfatto.
                                    <br />
                                    Per seguirti al meglio, offriamo i nostri
                                    servizi sotto forma di consulenza
                                    continuativa oppure realizziamo il software
                                    su misura per la tua attività.
                                    <br />
                                    Contattaci all’indirizzo{" "}
                                    <a href="mailto:info@appius.it">
                                        info@appius.it
                                    </a>{" "}
                                    e richiedi subito una call conoscitiva
                                    gratuitamente. Puoi chiamarci al numero{" "}
                                    <a href="tel:05361856617">05 361856617</a>,
                                    siamo pronti a rispondere a tutte le tue
                                    domande.
                                    <br />
                                    Saremmo davvero tanto entusiasti di lavorare
                                    al tuo fianco e scoprire la tua idea di
                                    felicità!
                                </p>

                                <blockquote style={{ textAlign: "right" }}>
                                    <p>
                                        <i>
                                            “Amare il proprio lavoro è la cosa
                                            che si avvicina più concretamente
                                            alla felicità sulla terra”
                                        </i>
                                    </p>
                                    <h6>-Rita Levi Montalcini</h6>
                                </blockquote>

                                <br />
                                <Link to="/contact" className="btn btn-primary">
                                    Contattaci
                                </Link>
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
